<script setup lang="ts">
const {t,locale,availableLocales, setLocale} = useI18n()

const languageStore = useLanguageStore()
 const languageStoreData = storeToRefs(languageStore)

 const {data, get:fetchFavIcon}=useOFetchCustom(`settings/favicon`)

 const favicon = ref<string>('')

 onMounted(()=>{
  setLocale(languageStoreData.language.value)
  fetchFavIcon(undefined,{
    onSuccess:()=>{
      favicon.value = data.value
      console.log(`output->onSuscces`)
      useHead({
      link: [
        { rel: 'icon', type: 'image/x-icon', href: favicon.value }
        ]
      });
    },
    headers:{
      'Content-Type': 'image/x-icon'
    }
  })
 })

</script>

<template>
  <div>
    <NuxtLayout >
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
