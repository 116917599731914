export default{
    all_status: "",
      all_coins: "all coins",
      countBuy: "count buy",
      sku: "SKU",
      name: "Name",
      lastname: "Lastname",
      email: "Email",
      password: "Password",
      current_password: "Current password",
      password_confirmation: "Confirm password",
      created_at: "Creation date",
      role: "Rol",
      no_role: "No Role",
      description: "Description",
      short_description: "Short description",
      instructions: "Instructions",
      active: "Active",
      initial: "Initial",
      final: "Final",
      variant: "Variant",
      price: "Price",
      price_up: "Price: From lowest to highest",
      price_down: "Price: From highest to lowest",
      stock: "Disponibility",
      stock_real: "Real availability",
      stock_current: "Current availability",
      images: "Images",
      image: "Image",
      galery_image: "Image gallery",
      phone: "Phone",
      company_name: "Company name",
      company_NIT: "NIT de la Empresa",
      company_phone: "Company telephone",
      company_address: "Company address",
      phones: "Phones",
      address: "Address",
      data: "Data",
      parent: "Parent",
      category: "Category",
      subtotal: "Subtotal",
      total: "Total",
      pay_form: "Pay form",
      note: "Note",
      quantity: "Quantity",
      current_quantity: "For sale",
      quantities: "Quantities",
      status: "Status",
      latest: "Latest",
      short_name: "Short name",
      symbol: "Symbol",
      position: "Posición",
      is_main: "Principal",
      categories: "Categories",
      regular_price: "Regular price",
      sale_price: "Sale price",
      wholesale_price: "Wholesale price",
      min_quantity: "Min quantity",
      virtual: "Virtual",
      downloadable: "Downlaodable",
      wholesaler: "Wholesaler",
      retail: "Retail",
      bookable: "Bookable",
      visible: "Visible",
      value: "Value",
      real_quantity: "Real quantity",
      initial_availability: "Historical amount",
      reserve: "Reserve",
      reserves: "Reserves",
      wholesale_quantities: "Wholesale quantities",
      real_reserve_availability: "Real reserve",
      wholesale_quantity: "Wholesale quantity",
      current_wholesale_quantity: "Current wholesale quantity",
      real_wholesale_quantity_availability: "Cantidad mayorista real",
      real_retail_quantity_availability: "Actual wholesale quantity",
      initial_wholesale_quantity_availability: "Initial wholesale quantity",
      date: "Date",
      coin: "Coin",
      exchange_rate: "Exchange rate",
      information: "Information",
      logo: "Logo",
      user: "User",
      debit_card: "Debit Card",
      confirmation_phone: "Confirmation phone",
      payment_timeout: "Payment waiting time (mn)",
      from: "From",
      to: "To",
      team_name: "Support team name",
      prepayment_percentage: "Prepayment percentage",
      number: "Number",
      client_id: "Client Id",
      client_secret: "Client Secret",
      cancelled: "Cancelled",
      date_payment: "Date payment",
      date_sale: "Date sale",
      type: "Type",
      available: "Available",
      duration: "Duration",
      from_commercial: "Commercial sender",
      to_commercial: "Recipient commercial",
      in_offer: "In offer",
      agree: "Yes, I am agree",
      unit: "Unit",
      message: "Message",
      enable: "Enable",
      location: "Location (html)",
      price_per_unity: "Price per unit",
      to_pay: "A pagar",
      pending: "Pendiente",
      prepayment_pay: "Prepayment pay",
      total_final: "Final amount",
      import_total: "Total amount",
      import_all_sale: "Total sales amount",
      only_wholesaler: "Wholesale only",
      import: "Import",
      prepayment_import: "Advance amount",
      product: "Product",
      import_product: "Product amount",
      import_for_customer: "Total customer amount",
      sale_id: "# Sale",
      invoices_num: "# Invoice",
      details: "Details",
      stock_wholesale: "Wholesale availability",
      order_id: "Order identifier",
      paid: "Paid",
      shipment: "Shipment",
      shipment_price: "Shipping cost",
      date_born: "Date born",
      shipment_distance: "Shipping distance",
      title_color: "Title color",
      title: "Title",
      body: "Body",
      summary: "Summary",
      author: "Author",
      content: "Content",
      price_fixed: "Fixed price",
      paid_for_kilometers: "Price per kilometer",
      min_import_for_free_shipping: "Minimum amount for free shipping",
      per_km_strategy_calculation: "Shipping calculation strategy in case of multiple points of sale",
      price_fixed_threshold: "Fixed price threshold",
      use_for_local_pickup: "Use for local pickup?",
      beneficiary: "My beneficiaries",
      shopping_card: "Shopping card",
      optional_price: "Price (optional)",
      code: "Code",
      remaining: "Remaining",
      card_beneficiary: "Beneficiaries",
      amount: "Amount",
      cart_item_timeout: "Waiting time for items in the cart (mn)",
      file: "File",
      data_sheet: "Technical sheet",
      upload_file: "Upload file",
      secret: "Secret",
      faq: "FAQ",
      ask: "Ask",
      coordinates: "Coordinates",
      is_stock_product: "Show product stock quantity",
      count_min: "Count min",
      time: "Time",
      low_availability: "Cantidad mínima en stock",
      availability_notification: "Low availability notification",
      only_base_coin: "Base currency only",
      history_count: "Historical Amount",
      coordinates_long: "Length",
      coordinates_lat: "Latitude",
      date_init: "Date init",
      date_end: "Date end",
      shopping_cards: "Gift Card",
      province: "Province",
      phone_code_country: "+53",
      max_quantity_for_home_shipping: "Max quantity for home delivery (in base unit)",
      departments: "Departments",
      open_label: "Opening hours",
      tax: "Tax (%)",
      tax_value: "Fee per payment method",
      priority: "Priority",
      outstanding: "Featured",
      favicon: "Favicon",
      keywords: "Keywords",
      warranty: "Warranty",
      time_warranty: "Warranty time",
      show_warranty: "Show warranty",
      unit_warranty: "Unit warranty",
      banner_category: "Banner images for the category",
      banner_category_phone: "Mobile banner images for category",
      client: "Client",
      contract : "Contract",
      almacen: "Warehouse",
      shoponline: "Online store",
      shoponlinealmacen: "Warehouse online store",
      reference_client: "Client reference",
      accounting_identify: "Order/Product Lines",
      accounting_description: "Order Lines/Description",
      accounting_quantity: "Order Lines/Quantity",
      accounting_unit : "Order Lines/Unit of Measure",
      accounting_price : "Order Lines/Unit Price",
      beneficiary_tittle: "Beneficiary",
      order_summary: "Summary",
      order_costs: "Import",
      disponibility_real_time: "Real-time availability",
      disponibility: "Disponibility",
      shop_cart_quantity: "Quantity in carts",
      locations: "Show on",
      mobile_image: "Mobile image",
      activated: "Activated",
      not_activated: "Disabled",
      orders_quantity: "Quantity in {statuses} orders ",
      time_period: "Time period",
      since: "Since",
      until: "Until",
      clear: "Clear",
      home_shipping: "Home delivery",
      shipment_unit_range: "Shipping Unit Ranges",
      min: "Min",
      max: "Max",
      current_strategy: "Shipping strategy",
      shipment_unit: "Shipping unit",
      shipment_provinces: "Shipping provinces",
      use_free_shipping_threshold: "Minimum amount for free shipping",
      disponibility_from_store: "Use inventory from",
      second_beneficiary_title: "Alternative beneficiary",
      seconds_beneficiary_title: "Alternative beneficiaries",
      no_alternative_beneficiary: "No alternative beneficiary was registered",
      orderable_status: "Order status",
      sale_type: "Sale",
      reservation_type: "Wholesaler",
      all: "All",
      paid_at: "Paid at",
      payment_status: "Payment status",
      schedule_management: "Schedule management",
      send_email_beneficiary: "Email to purchase beneficiary",
      municipality:"Municipality",
      prices: "Prices",
      generals: "Generals",
      inventory: "Inventory",
      public_key: "Public key",
      secret_key: "Secret key",
      free_shipping_message: "Message to show in cart when free shipping",
      count_products:"Number of products",
      search_country: "Select country",
      ratings: "Ratings ( {number} )",
      order_by: "Order by:",
      municipalities: "Municipalities",
      main_street: "Main street",
      first_street: "Between street 1",
      second_street: "Between street 2",
      house_or_apartment: "House No., Apt. No. and buildings, etc.",
      point_of_reference: "Point of reference",
      contacted: "Contacted",
      company_images: 'Company Images',
      add_image: 'Reference images',
      add_company_image: 'Images of company identification document',
      add_image_hint: 'Add maximum of {count} image |Add maximum of {count} images',
      products:"Products",
      brands:"Brands",
      fullname: "Full Name",
      reason: "Reason",
      changed_at: "Changed At",
      discount_percentage: "Discount (%)",
      offer: "Offert",
      discount_price: "Price with the discount",
      description_details:"Description details",
      prepayment_porcent:"Prepayment percentage",
      quantity_min:"Minimum purchase quantity",
      subtotal_to_pay:"Subtotal to pay",
      point_of_sale:"Local pickup",
      delivery:"Delivery",
      possible_delivery_date:"Possible delivery date",
      count_bookable:"Count bookable",
      record_availability:"Record availability",
      alert:'Alert'
}
