export default{
    delete_confirmation: "Confirmar eliminación",
    delete_confirmation_msg: "Esta acción no se puede deshacer. ¿Está seguro/a?",
    forgot_password: "¿Olvidó su contraseña?",
    forgot_password_msg: "¿Olvidó su contraseña? No hay problema. Solo háganos saber su dirección de correo y le enviaremos un correo para restablecer su contraseña",
    already_registered: "¿Ya tiene una cuenta?",
    no_have_account: "¿No tiene una cuenta?",
    confirm_password: "Esta es un area segura de la aplicación. Por favor, confirme su contraseña antes de continuar",
    email_unverified: "Su dirección de correo electrónico no está verificada",
    verification_resent: "Se ha enviado un nuevo enlace de verificación a su dirección de correo electrónico",
    out_stock: "Agotado",
    in_stock: "Disponible",
    available_stock: "Solo ",
    commas_phones: "Separados por comas",
    admin_notifications: "Notificar eventos a los administradores",
    auto_sku: "Generar SKU de los productos automáticamente",
    enable_branch_offices: "Usar varios locales de recogida",
    pay_forms_subtitle: "Configure las formas de pago que se usarán en la plataforma.",
    no_pay_forms: "No hay formas de pago disponibles.",
    payment_timeout: "Tiempo disponible para que los usuarios paguen sus pedidos.",
    created_successfully: "Creado exitosamente.",
    not_more_20: "Por favor use menos de 20 caracteres",
    not_more_50: "Por favor use menos de 50 caracteres",
    failed_creating_invoice: "No se pudo crear la factura.",
    invoice_already_exists: "Ya existe una factura.",
    external_redirect: "A continuación será redirigido a un sitio externo.",
    pending_to_pay: "Pendiente a pagar",
    unavailable: "No disponible",
    available: "Disponible",
    leave_message: "Déjenos un mensaje",
    location_help: "Vaya a Google maps, busque la ubicación deseada, compártala y copie el código html proporcionado",
    analytics_message: "Obtén información sobre cómo la gente encuentra tu sitio, así como sobre cómo mejorar y optimizar el contenido",
    wholesale_prepayment: "Pago adelantado en venta mayorista",
    change_status_success: "Estado cambiado exitosamente",
    change_status_error: "No se pudo cambiar el estado",
    need_for_distribution: "Esta orden necesita ser despachada",
    order_paid: "Pago aceptado con éxito",
    error_on_shipment_calculation: "Ha ocurrido un error en el cálculo en el precio del envío",
    not_more_12: "Por favor use menos de 12 caracteres",
    not_more_30: "Por favor use menos de 30 caracteres",
    manual_relateds: "Esta selección solo se utilizará si la configuración del sistema sobre productos relacionados es 'Manual'",
    recharge_paid: "Pago aceptado con éxito",
    most_selled_roduct: "Productos más vendidos",
    tooltip_file: "Usted solo puede subir un archivo en formato .pdf o .docx",
    tooltip_file_responsive: "Solo un archivo .pdf o .docx",
    tooltip_image: " Solamente subir imagenes de tipo .png,.jpg, .webp, .avif o .jiff",
    galery_image: "Añadir imágenes",
    stock_product: "Cantidad mínima que se mostará del producto",
    notification_user: "Notificar los pedidos que se realizan en el sistema",
    notification_stock: "A los productos que no tengan configurado la baja disponibilidad se les iniciará en 1/4 de su disponibilidad en stock",
    validation_phone: "El número de teléfono no cumple con su formato",
    coordination_helps: "Longitud / Latitud ej: -82.42189014665645 / 23.11644723797522",
    need_map_selection: "Es necesario que seleccione su ubicación en el mapa",
    need_beneficiary: "Es necesario que seleccione un beneficiario",
    this_field: "El campo ",
    field_required: " es requerido",
    need_province: "El beneficiario de compra requiere una provincia para el envío",
    order_not_paid: "No se puede pagar",
    empty_fields: "Hay campos vacíos",
    new_quantity: "Nueva cantidad",
    products_beyond_max: "Estos productos sobrepasan la cantidad máxima para el envío a domicilio; puede continuar su pedido y recoger en nuestro almacén",
    max: "Máx.",
    current: "Actual",
    search_all_store: "Buscar en toda la tienda",
    order_created: "Su orden ha sido creada con éxito",
    go_to_order: "Ver orden",
    go_to_store: "Ir a la tienda",
    need_new_beneficiary: "Es necesario que agregue y seleccione un beneficiario",
    use_store_address_as_pickup_option: "Usar dirección de la tienda como opción de recogida",
    notification_email_manual_invoice:"Notificar por correo",
    notification_text_description_email_manual_invoice:"Se le enviará un correo de confirmación de la compra a los administradores, cliente y beneficiario",
    count_not_disponibility:"Cantidades no permitidas",
    disponibility_formulae: "Se calcula restando de la cantidad para la venta la cantidad en carritos y la cantidad en pedidos con estado {statuses}",
    discount_in_status: "Descontar del inventario en estado",
    change_visible: "Cambiar estado de visibilidad",
    shipment_unit_range_format: "Debe comenzar con un rango con formato [0 - X] y terminar con uno [Y - ∞]",
    leave_blank_for_infinity: "Dejar en blanco para ∞",
    error_quantity: "Cantidad no permitida",
    no_data: "No hay datos",
    rating_product: "Valorar producto: {name}",
    time_order:"Se deben introducir las horas en orden ascendente, de menor a mayor",
    notification_shopping:"Notificaciones de compra",
    send_email_beneficiary:"Habilitar el envío de correos a los beneficiarios de compras",
    need_municipalit:"El beneficiario de compra requiere un municipio de envío",
    need_address:"El beneficiario de compra requiere una dirección de envío",
    no_variations: "No hay variaciones",
    max_characters: "Máx. {max} caracteres",
    max_chars: "Máximo {max} caracteres.",
    aspect_ratio_banner:"(Aspect ratio 30/12)",
    aspect_ratio_banner_movil:"(Aspect ratio 1/1)",
    error_min_border:"El ancho del borde no es permitido.",
    errror_formatted_phone:"Formato de teléfono no válido.",
    writevaloration: "Escriba su opinión sobre el producto antes de continuar",
    go_to_product: "Ir a detalles del producto",
    pending_review: "Su comentario esta siendo revisado por nuestro equipo de soporte",
    pending_review_description: "Para ofrecer una mejor experiencia a nuestro clientes los comentarios que se suben a la plataforma pasan por un proceso de revisión.",
    images_limit:"Las imágenes no deben sobrepasar los 300kb.",
    not_more_300: "Ha excedido el máximo número de caracteres permitido.",
    not_more_250: "Por favor entre 250 caracteres como máximo.",
    reason_of_rejection: "Motivo de rechazo",
    input_field_required: "Campo obligatorio",
    not_more_50_two: "Se admite máximo 50 caracteres.",
    tooltip_image_review: "Solamente subir imagenes de tipo .jpg, .jpeg, .avif, .webp, .png o .jiff",
    available_at: "Disponible en",
    copied_to_clipboard: "Copiado en el portapapeles",
    selected: "{count} selecc.",
    not_cost_especific:"Sin costo específico",
    error_priority:"Prioridad no permitida",
    all_brands:"Todas las marcas",
    download_product_specification_sheet: "Descargar ficha técnica del producto",
    loading_cart: "Cargando carrito",
    product_not_available: "Producto no disponible en el local actual",
    reason_changed: "Motivo del estado \"{status}\" (Máximo 250 caracteres)",
    reason: "Motivo",
    no_reason: "Sin motivo",
    error_send_new_lawsuit: "Error al enviar la solicitud.",
    error_update_profile: "Error al actualizar el perfil.",
    error_field_date_init: "El campo fecha inicial es obligatorio.",
    error_field_name: "El campo nombre es obligatorio.",
    error_field_discount_percentage: "El porciento de descuento es obligatorio.",
    error_field_date_end: "El campo fecha final es obligatorio.",
    error_field_products_ids: "Debe seleccionar al menos 1 producto.",
    delete_offer: "Oferta eliminada satisfactoriamente",
    error_delete_offer: "No se pudo eliminar la oferta",
    delete_user: "Usuario eliminado satisfactoriamente",
    error_delete_user: "No se pudo eliminar el usuario",
    delete_offers: "Ofertas eliminadas satisfactoriamente",
    error_delete_offers: "No se pudieron eliminar las ofertas",
    error_create_user: "Error creando el usuario",
    error_update_user: "Error editando el usuario",
    select_related_products:"Seleccionar productos relacionados",
    error_login: "Los datos introducidos no son correctos",
    selected_categories:"Al escoger categorías el banner aparecerá en ellas solamente",
    error_create_status: "Error al crear el estado",
    delete_order_status: "Estado eliminado satisfactoriamente",
    error_delete_order_status: "No se pudo eliminar el estado",
    email_sent: "Correo electrónico enviado satisfactoriamente",
    error_required_email_sent: "El campo correo electronico es obligatorio",
    delete_rol: "Rol eliminado satisfactoriamente",
    error_delete_rol: "No se pudo eliminar el rol",
    delete_category: "Categoría eliminada satisfactoriamente",
    error_delete_category: "No se pudo eliminar la categoría",
    more_quantity:" + de {quantity} unidades",
    more_quantity_big:"Precio por + de {quantity} unidades",
    error_update_mail: "Error al editar las configuraciones del correo para envío",
    error_field_notification_users: "El campo usuarios para notificación es obligatorio",
    error_field_current_password: "La contraseña es incorrecta",
    go_to_operation:"Ir a detalles de la operación",
    operation_created:"Su operación ha sido creada con éxito",
    prepayment_of_sale:"{prepayment} de {price}",
    errror_formatted_email:"Formato de email no válido.",
    not_have_permission:"No tiene permiso",
    not_revert_movements:"Esta acción no se puede revertir, revise bien las cantidades.",
    check_tabs:"Revise las pestañas"
}