<template>
    <MainLayout>
        <template #drawerBtn>
        <q-btn class="lg:tw-hidden" dense flat round icon="menu" @click="toggleLeftDrawer"/>
        </template>
        <template #drawer>
            <LeftDrawer v-model="leftDrawerOpen"></LeftDrawer>
        </template>
        <div >
            <slot></slot>
        </div>

        <template #brand>
            <slot name="brand">
                <CarouselBrand :items="brands" class="tw-border-t"></CarouselBrand>
            </slot>
        </template>

    </MainLayout>
</template>
<script lang="ts" setup>
import MainLayout from './MainLayout.vue'
import LeftDrawer from "~/components/LeftDrawer.vue";
import { ref, onMounted } from 'vue';
import { useOFetchCustom } from '../composables/useOFetchCustom';

const leftDrawerOpen = ref(false)

const {loading:loadingBrand, get:fetchBrand}=useOFetchCustom('brands')

const brands =ref([])
function toggleLeftDrawer() {
  leftDrawerOpen.value = !leftDrawerOpen.value
}

onMounted(()=>{
    fetchBrand(undefined,{
        onSuccess:(response:any)=>{
            brands.value = response.brands
        }
    })
})

</script>