<script setup lang="ts">
import ChangeLocale from './ChangeLocale.vue';
import CoinSelect from './CoinSelect.vue';
import ContactEmail from './ContactEmail.vue';

const defaultSettingsStore = useDefaultSettingsStore()
  const {settings} = storeToRefs(defaultSettingsStore)
</script>
<template>
     <nav class="tw-w-full  tw-bg-white ">
        <div class="tw-border-b tw-border-gray-200 tw-text-gray-500 tw-w-full home-nav-px tw-flex tw-flex-row tw-justify-center lg:tw-justify-between">
            <div class="tw-border-x tw-border-gray-200 tw-p-2 tw-w-max tw-g-full lg:tw-flex tw-hidden ">
              <span class="tw-text-center tw-text-gray-300 tw-self-center tw-text-base tw-font-normal tw-px-4 ">{{ $t('settings.messages_welcome') }}</span>
            </div>
            <div class="tw-w-max tw-flex">
              <div class="tw-border-l tw-border-gray-200 tw-w-max tw-h-full tw-p-2 tw-text-center tw-flex">
                <span class="tw-self-center tw-px-2">{{ 'USD' }}</span>
              </div>
              <div class="tw-border-x tw-border-gray-200 tw-w-max tw-h-full tw-p-2">
                <ChangeLocale/>
              </div>
              <div class="tw-border-r tw-border-gray-200 tw-w-max tw-h-full tw-p-2 tw-flex tw-items-center">
                <ContactEmail :mail="settings?.data?.contacts?.email"></ContactEmail>
              </div>
            </div>
        </div>
      </nav>
</template>