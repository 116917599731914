<template >
    <q-layout view="hHh lpR fff" :style="{minHeight: '100vh'}" >
        <q-header >
          
          <HomeNav>
            
              <template #drawerBtn>
                <slot name="drawerBtn"> </slot>  
              </template>
            
            
          </HomeNav>
  
          
        </q-header>
        
        <!-- <slot name="drawer" >
            <LeftDrawer v-model="leftDrawerOpen" show-if-above >
              <LeftMenu :menu="main_menu_filtered" />
            </LeftDrawer>
        </slot> -->
  ``
        <q-page-container >
         
          <q-page :style="{ minHeight: '100vh' }">

            <!-- <template >
                    <slot name="banner">
                      
                    </slot>
                </template> -->
            <slot name="offers"></slot>  
            
            <div class="tw-w-full">
              <slot name="breadCrumbs">
                <BreadCrumbs :crumb="breadCrumb_menu" class="tw-mb-5"/>
              </slot>
            </div>
            <div>
              <slot></slot>
              <slot name="most_sold"></slot>  
            </div>
            
          </q-page>
          
        </q-page-container>
        
        <q-footer class="footer !tw-py-0">
          <div class="tw-flex tw-flex-col">
           
  
           <slot name="brand"></slot>
          </div>
          <HomeFooter></HomeFooter>
        </q-footer>
    </q-layout>
  </template>
  
  <script setup lang="ts">
  
  import HomeNav from '../components/HomeNav.vue'
  import HomeFooter from '../components/HomeFooter.vue';
  import LeftDrawer from '../components/LeftDrawer.vue';
  import { onMounted, ref, onBeforeMount } from 'vue';
  import { useLeftMenu } from '../composables/LefMenu';
  import { useNotification } from '../composables/Notification';
  import { watch } from 'vue';
  import { computed } from 'vue';
  import {QAjaxBar, useQuasar} from 'quasar';
  import BreadCrumbs from '~/components/BreadCrumbs.vue';
  import { breadCrumb_menu } from '~/common/crumbs_menu';
  import { timeClearSpinner } from '../common/helpers';
  import { useOFetchCustom } from '../composables/useOFetchCustom';
  
  const router = useRouter()
  const route = useRoute()
  
  const leftDrawerOpen = ref(false)
  const main_menu_filtered = useLeftMenu()
  const menuItemSelectedStore = useMenuItemSelectedStore()
  
  const shopCartStore = useShopCartStore()
  const $q = useQuasar()
  
  let timer: NodeJS.Timeout;
  
  const {intentNotificate} = useNotification()
  
  const bar = ref<QAjaxBar|null>(null)
  
  const initSettingsStore = useDefaultSettingsStore()
  const contactsStore = useContactsStore()
  
  
  const storeId = ref(0);  
  
  //onCreated
  shopCartStore.fetchShopCart({'storage_id': storeId.value})
  
  const {get:fetchInit, data} = useOFetchCustom('init-settings')
  
  //finish onCreated
  
  watch(storeId, (newValue) => {
    storeId.value = newValue;
    shopCartStore.fetchShopCart({'storage_id': storeId.value})
  });
  
  onMounted(async () => {
  
    useNuxtApp().$bus.$on('update:product2', ()=> {
      updateStore();
    });
  
    updateStore();
  
    const route_name = router.currentRoute.value.name
  
    if(route_name)
    {
      menuItemSelectedStore.setByRoute(route_name.toString())
    }
  
    useNuxtApp().$bus.$on('update:settings',()=>{
      initSetting()
    });
  
  })
  
  onUnmounted(()=>{
      useNuxtApp().$bus.$off('update:product2');
  
  })
  
  onBeforeMount(()=>{
    initSetting()
  })
  
  const initSetting = ()=>{
    fetchInit({category:1},{
        onSuccess:()=>{
          initSettingsStore.setSettings(data?.value)
          contactsStore.setContacts(data?.value.contacts)
        }
      })
  }
  
  const updateStore = ()=>{
    const storedValue = JSON.parse(localStorage.getItem('storeSelected'));
    if(storedValue){
      storeId.value = storedValue.id
    }
  }
  
  </script>
  
  
  <style>
  .shadown{
    box-shadow: 0px 4px 4px 0px rgba(6, 116, 191, 0.15);
  }
  .body-blur{
    filter: blur(3px);
  }
  </style>
  