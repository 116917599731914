export default {
    delete_confirmation: "Delete Confirmation",
    delete_confirmation_msg: "This action cannot be undone. Are you sure?",
    forgot_password: "Forgot your password?",
    forgot_password_msg: "Forgot your password? No problem. Just let us know your email address, and we’ll send you a link to reset your password.",
    already_registered: "Already have an account?",
    no_have_account: "Don't have an account?",
    confirm_password: "This is a secure area of the application. Please confirm your password before continuing.",
    email_unverified: "Your email address is not verified.",
    verification_resent: "A new verification link has been sent to your email address.",
    out_stock: "Out of Stock",
    in_stock: "In Stock",
    available_stock: "Only ",
    commas_phones: "Separated by commas",
    admin_notifications: "Notify events to administrators",
    auto_sku: "Automatically generate SKUs for products",
    enable_branch_offices: "Use multiple pickup locations",
    pay_forms_subtitle: "Configure the payment methods to be used on the platform.",
    no_pay_forms: "No payment methods available.",
    payment_timeout: "Time available for users to pay their orders.",
    created_successfully: "Successfully created.",
    not_more_20: "Please use fewer than 20 characters.",
    not_more_50: "Please use fewer than 50 characters.",
    failed_creating_invoice: "Failed to create the invoice.",
    invoice_already_exists: "An invoice already exists.",
    external_redirect: "You will now be redirected to an external site.",
    pending_to_pay: "Pending Payment",
    unavailable: "Unavailable",
    available: "Available",
    leave_message: "Leave us a message",
    location_help: "Go to Google Maps, find the desired location, share it, and copy the provided HTML code.",
    analytics_message: "Get insights into how people find your site and ways to improve and optimize content.",
    wholesale_prepayment: "Prepayment for wholesale sales",
    change_status_success: "Status changed successfully.",
    change_status_error: "Failed to change the status.",
    need_for_distribution: "This order needs to be dispatched.",
    order_paid: "Payment successfully accepted.",
    error_on_shipment_calculation: "An error occurred while calculating the shipping cost.",
    not_more_12: "Please use fewer than 12 characters.",
    not_more_30: "Please use fewer than 30 characters.",
    manual_relateds: "This selection will only be used if the system configuration for related products is 'Manual'.",
    recharge_paid: "Payment successfully accepted.",
    most_selled_roduct: "Best-Selling Products",
    tooltip_file: "You can only upload files in .pdf or .docx format.",
    tooltip_file_responsive: "Only one file in .pdf or .docx format.",
    tooltip_image: "Only upload images in .png, .jpg, .webp, .avif, or .jiff format.",
    galery_image: "Add images",
    stock_product: "Minimum quantity to be displayed for the product.",
    notification_user: "Notify system orders to users.",
    notification_stock: "For products without configured low stock availability, it will start at 1/4 of their stock availability.",
    validation_phone: "The phone number format is invalid.",
    coordination_helps: "Longitude/Latitude e.g., -82.42189014665645 / 23.11644723797522",
    need_map_selection: "You must select your location on the map.",
    need_beneficiary: "You must select a beneficiary.",
    this_field: "The field ",
    field_required: " is required.",
    need_province: "The purchase beneficiary requires a province for delivery.",
    order_not_paid: "Cannot be paid.",
    empty_fields: "There are empty fields.",
    new_quantity: "New quantity",
    products_beyond_max: "These products exceed the maximum quantity for home delivery; you can proceed with your order and pick it up at our store.",
    max: "Max.",
    current: "Current",
    search_all_store: "Search the entire store",
    order_created: "Your order has been successfully created.",
    go_to_order: "View order",
    go_to_store: "Go to store",
    need_new_beneficiary: "You need to add and select a beneficiary.",
    use_store_address_as_pickup_option: "Use store address as a pickup option.",
    notification_email_manual_invoice: "Notify by email",
    notification_text_description_email_manual_invoice: "A purchase confirmation email will be sent to administrators, the client, and the beneficiary.",
    count_not_disponibility: "Quantities not allowed",
    disponibility_formulae: "Calculated by subtracting the quantity in carts and orders with status {statuses} from the sale quantity.",
    discount_in_status: "Deduct from inventory in status.",
    change_visible: "Change visibility status.",
    shipment_unit_range_format: "Must start with a range formatted as [0 - X] and end with one [Y - ∞].",
    leave_blank_for_infinity: "Leave blank for ∞.",
    error_quantity: "Quantity not allowed.",
    no_data: "No data.",
    rating_product: "Rate product: {name}",
    time_order: "Hours must be entered in ascending order, from lowest to highest.",
    notification_shopping: "Shopping notifications",
    send_email_beneficiary: "Enable sending emails to purchase beneficiaries.",
    need_municipalit: "The purchase beneficiary requires a municipality for delivery.",
    need_address: "The purchase beneficiary requires a shipping address.",
    no_variations: "No variations.",
    max_characters: "Max. {max} characters.",
    max_chars: "Maximum {max} characters.",
    aspect_ratio_banner: "(Aspect ratio 30/12)",
    aspect_ratio_banner_movil: "(Aspect ratio 1/1)",
    error_min_border: "Border width not allowed.",
    errror_formatted_phone: "Invalid phone format.",
    writevaloration: "Write your review of the product before continuing.",
    go_to_product: "Go to product details.",
    pending_review: "Your comment is being reviewed by our support team.",
    pending_review_description: "To offer a better experience for our customers, comments uploaded to the platform go through a review process.",
    images_limit: "Images must not exceed 300KB.",
    not_more_300: "You have exceeded the maximum number of characters allowed.",
    not_more_250: "Please enter no more than 250 characters.",
    reason_of_rejection: "Reason for rejection",
    input_field_required: "Required field",
    not_more_50_two: "A maximum of 50 characters is allowed.",
    tooltip_image_review: "Only upload images in .jpg, .jpeg, .avif, .webp, .png, or .jiff format.",
    available_at: "Available at",
    copied_to_clipboard: "Copied to clipboard.",
    selected: "{count} selected.",
    not_cost_especific: "No specific cost.",
    error_priority: "Priority not allowed.",
    all_brands: "All brands",
    download_product_specification_sheet: "Download product specification sheet.",
    loading_cart: "Loading cart.",
    product_not_available: "Product not available at the current location.",
    reason_changed: "Reason for \"{status}\" status (Maximum 250 characters).",
    reason: "Reason.",
    no_reason: "No reason.",
    error_send_new_lawsuit: "Error submitting the request.",
    error_update_profile: "Error updating the profile.",
    error_field_date_init: "The start date field is required.",
    error_field_name: "The name field is required.",
    error_field_discount_percentage: "The discount percentage is required.",
    error_field_date_end: "The end date field is required.",
    error_field_products_ids: "At least one product must be selected.",
    delete_offer: "Offer successfully deleted.",
    error_delete_offer: "Failed to delete the offer.",
    delete_user: "User successfully deleted.",
    error_delete_user: "Failed to delete the user.",
    delete_offers: "Offers successfully deleted.",
    error_delete_offers: "Failed to delete the offers.",
    error_create_user: "Error creating the user.",
    error_update_user: "Error editing the user.",
    select_related_products: "Select related products.",
    error_login: "The entered data is incorrect.",
    selected_categories: "When choosing categories, the banner will only appear in them.",
    error_create_status: "Error creating the status.",
    delete_order_status: "Status successfully deleted.",
    error_delete_order_status: "Failed to delete the status.",
    email_sent: "Email successfully sent.",
    error_required_email_sent: "The email field is required.",
    delete_rol: "Role successfully deleted.",
    error_delete_rol: "Failed to delete the role.",
    delete_category: "Category successfully deleted.",
    error_delete_category: "Failed to delete the category.",
    more_quantity: " + of {quantity} units.",
    more_quantity_big: "Price for + of {quantity} units.",
    error_update_mail: "Error editing email settings for sending.",
    error_field_notification_users: "The notification users field is required.",
    error_field_current_password: "The password is incorrect.",
    go_to_operation: "Go to operation details.",
    operation_created: "Your operation has been successfully created.",
    prepayment_of_sale: "{prepayment} of {price}.",
    errror_formatted_email: "Invalid email format.",
    not_have_permission: "You do not have permission.",
    not_revert_movements:"This action cannot be reversed, check the quantities carefully.",
    check_tabs:"Check the tabs"
};