export default{
    title: "Reportes",
    for_sale: "Ventas",
    for_inventary: "Inventario",
    for_point_sale: "Local de recogida",
    for_genary_sale: "Venta General",
    for_wholesale: "Ventas mayoristas",
    for_retail: "Ventas minoristas",
    orders: "Pedidos",
    movements: "Movimientos",
    salesaccounting : "Ventas para contabilidad",
    sold_product: "Productos vendidos",
    sale_title: "Reporte de ventas",
    inventory_title: "Reporte de inventario",
    movements_title: "Reporte de movimientos",
    sold_products_title: "Reporte de productos vendidos",
    movements_file: "Listado de movimientos {date}",
}